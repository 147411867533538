<template>
  <a-modal
    wrap-class-name="apply-modal"
    :visible="visible"
    :title="$t('pages_exchange_034')"
    :mask-closable="false"
    :confirm-loading="loading"
    @ok="handleSubmit"
    @cancel="$emit('update:visible', false)"
  >
    <div class="exchange-info">
      <div class="sell-info">
        <div class="title">{{ $t('pages_exchange_003') }}</div>
        <div class="area flex-align">
          <span class="area-left">{{ $t('pages_exchange_035') }}</span>
          <div class="area-right">
            <img :src="getCountryLogo(exchangeInfo.sellCurrency)">
            <span>{{ exchangeInfo.sellCurrency }}</span>
          </div>
        </div>
        <div class="area flex-align">
          <span class="area-left">{{ $t('pages_exchange_036') }}</span>
          <div class="area-right">{{ parseMoney(exchangeInfo.sellAmount, exchangeInfo.sellCurrency) }}</div>
        </div>
      </div>
      <div class="buy-info">
        <div class="title">{{ $t('pages_exchange_002') }}</div>
        <div class="area flex-align">
          <span class="area-left">{{ $t('pages_exchange_035') }}</span>
          <div class="area-right">
            <img :src="getCountryLogo(exchangeInfo.buyCurrency)">
            <span>{{ exchangeInfo.buyCurrency }}</span>
          </div>
        </div>
        <div class="area flex-align">
          <span class="area-left">{{ $t('pages_exchange_036') }}</span>
          <div class="area-right">{{ parseMoney(exchangeInfo.buyAmount, exchangeInfo.buyCurrency) }}</div>
        </div>
      </div>
      <div class="rate-info" style="border-bottom: 0">
        <div class="title">{{ $t('pages_exchange_037') }}</div>
        <div class="area flex-align">
          <span class="area-left">{{ $t('pages_exchange_038') }}</span>
          <div class="area-right">{{ exchangeInfo.rate }}</div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { reactive, toRefs, h } from 'vue'
import { useRouter } from 'vue-router'
import { getCountryLogo, parseMoney } from '@/util'
import { notification, Button } from 'ant-design-vue'
import i18n from '@/locale'
import { _exchange } from '@/api'
export default {
  name: 'ExchangeApply',
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    },
    exchangeInfo: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup(props, ctx) {
    const router = useRouter()
    const state = reactive({
      loading: false
    })

    const handleSubmit = async () => {
      state.loading = true
      const { exchangeInfo } = props
      const data = Object.assign({}, exchangeInfo, {})
      const res = await _exchange.exchangeApply(data)
      state.loading = false
      if (res.data.succ) {
        let message = i18n.global.t('pages_exchange_039')
        if (exchangeInfo.lockRateType === '10') message = i18n.global.t('pages_exchange_040')
        if (exchangeInfo.lockRateType === '20') message = i18n.global.t('pages_exchange_041')
        if (exchangeInfo.lockRateType === '30') message = i18n.global.t('pages_exchange_042')
        notification.success({
          message,
          description: i18n.global.t('pages_exchange_043'),
          btn: () => {
            return h(Button, {
              type: 'primary',
              size: 'small',
              onClick: () => {
                notification.destroy()
                router.push('/exchange/details')
              }
            }, i18n.global.t('pages_exchange_044'))
          }
        })
        ctx.emit('success')
      } else {
        notification.error({
          message: i18n.global.t('common_text_015'),
          description: res.data.resMsg
        })
      }
    }

    return {
      parseMoney,
      getCountryLogo,
      handleSubmit,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
.apply-modal {
  .exchange-info {
    background: #f8f8f8;
    padding: 12px 18px;
    border-radius: 5px;
    .sell-info, .buy-info, .rate-info {
      margin-bottom: 24px;
      border-bottom: 1px dashed #999;
      .title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 18px;
      }
      .area {
        padding: 0 12px 18px 12px;
        .area-right {
          margin-left: auto;
          img {
            width: 25px;
            height: 18px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>

